<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ page_name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form size="medium" :label-width="this.env.label_width">
                        <el-form-item label="更新时间">
                            {{ update_time }}
                        </el-form-item>
                        <el-form-item label="协议内容">
                            <!-- 富文本编辑框 -->
                            <Wangeditor ref="wangeditor" v-model="control" idindex="1" seewWidth="50%"/>
                        </el-form-item>
                        <el-form-item label="隐私协议">
                            <!-- 富文本编辑框 -->
                            <Wangeditor ref="wangeditor_privacy" v-model="privacy" idindex="2" seewWidth="50%"/>
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="this.Tool.is_auth('agreement.coach.issave')" size="medium" type="primary"
                                       @click="save">保存
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Wangeditor from '@/components/Wangeditor'

export default {
    components: {
        Wangeditor,
    },
    data() {
        return {
            page_name: '教练注册协议',
            control: '',
            privacy: '',
            update_time: '',
        }
    },
    // 创建
    created() {
    },
    // 安装
    mounted() {
        this.getinfo();
    },
    methods: {
        // 获取注册协议
        getinfo() {
            let postdata = {
                api_name: 'agreement.coach.getinfo',
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.update_time = json.data.update_time
                    this.$refs.wangeditor.append(json.data.control);
                    this.$refs.wangeditor_privacy.append(json.data.privacy);
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        // 保存注册协议
        save() {
            let postdata = {
                api_name: "agreement.coach.issave",
                token: this.Tool.get_l_cache('token'),
                control: this.control,
                privacy: this.privacy,
            }
            this.Tool.post_data('oss', postdata, (json) => {
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getinfo()    // 刷新数据
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
